@import url("https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap");

:root {
    --primary: #db5860;
}

::-webkit-scrollbar {
    background: transparent;
    width: 5px;
}

::-webkit-scrollbar-thumb {
    background: var(--primary);
    border-radius: 100px;
}

body,
body * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    font-family: "Fira Sans", sans-serif;
    color: #fff;
    letter-spacing: 0.7px;
}

a {
    text-decoration: none;
}

/* ===================================== common css start =============================================== */

.icon {
    color: #fff;
    font-size: 20px;
}

.container {
    background: #1e1e1e;
    width: "100%";
    height: 100vh;
    flex-direction: row;
    overflow: hidden;
}

.sidebar {
    width: 250px;
    height: 100vh;
    background-color: #000;
    margin-right: 20px;
    box-shadow: 0 0 7px var(--primary);
    padding: 20px;
    padding-top: 40px;
}

.container main {
    width: calc(100% - 270px);
    padding-right: 20px;
    height: 100%;
    overflow-y: auto;
    padding-bottom: 20px;
}

.sidebar .list {
    text-decoration: none;
    flex-direction: row;
    padding: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    margin: 5px 0;
}

.sidebar .list i {
    margin-right: 20px;
    transition: all 300ms linear;
}

.sidebar .list span {
    transition: all 300ms linear;
    font-size: 14px;
}

.sidebar .list:hover {
    border-bottom: 1px solid var(--primary);
}

.sidebar .list:hover > span,
.sidebar .list:hover > i {
    color: var(--primary);
}

.sidebar .list.active {
    background: var(--primary);
    align-items: center;
    border-radius: 10px;
    border-bottom: none;
}

.sidebar .list.active:hover > span,
.sidebar .list.active:hover > i {
    color: #fff;
}

.header {
    width: 100%;
    height: 35px;
    margin-top: 25px;
    border-bottom: 1px solid #95a5a6;
    padding-left: 20px;
    color: #ecf0f1;
}

.cards {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
    padding-bottom: 20px;
}

.card {
    width: 350px;
    height: 200px;
    border-radius: 12px;
    background: #000;
    box-shadow: 1px 1px 5px var(--primary);
    position: relative;
    cursor: pointer;
}

.card::after {
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 10px;
    display: none;
}

.card:hover::after {
    display: flex;
}

.btn {
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    font-size: 14px;
    background: var(--primary) !important;
    position: relative;
    cursor: pointer;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.btn::after {
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 10px;
    display: none;
}

.btn:hover::after {
    display: flex;
}

.btn i {
    margin-right: 5px;
    font-size: 18px;
}

.ReactModal__Overlay {
    background: rgba(255, 255, 255, 0.2) !important;
}

.input-container {
    position: relative;
    width: 100%;
}

.input-container label {
    font-size: 14px;
    position: absolute;
    left: 10px;
    top: 0;
    transition: top 300ms linear;
    background: #000;
    color: silver;
    cursor: inherit;
}

.input-container input {
    background: transparent;
    border: none;
    outline: none;
    font-size: 14px;
    padding: 10px;
    border-bottom: 1px solid #95a5a6;
    color: #fff;
}

.input-container input:focus {
    border-bottom: none;
    box-shadow: 0 0 5px var(--primary);
}

.input-container label.active {
    color: var(--primary);
}

.dropdown {
    font-size: 14px;
    width: 200px;
}

.dropdown .Dropdown-option {
    background: #000;
    padding: 5px 10px;
    cursor: pointer;
    margin: 1px 0;
    font-size: 13px;
}

.dropdown .Dropdown-option:hover {
    background: var(--primary);
}

.dropdown .Dropdown-placeholder {
    border-radius: 8px;
    padding: 8px 10px;
    cursor: pointer;
    margin-bottom: 5px;
    color: #ecf0f1;
    border: 1px solid var(--primary);
}

.dropdown-wrapper {
    flex-direction: row;
    flex-wrap: wrap;
    max-width: calc(100% - 200px);
}

.dropdown-wrapper .dropdown {
    margin: 10px;
}

.date-picker {
    background: transparent;
    color: #fff;
    border: none;
    outline: none;
    border-bottom: 1px solid #95a5a6;
    padding: 10px;
    width: 100%;
}

.react-datepicker * {
    display: block;
    z-index: 99;
}

.react-datepicker__time-list-item {
    color: #000;
}

.date-label {
    font-size: 14px;
    margin-top: -10px;
    background: #1e1e1e;
    color: var(--primary);
    margin-left: 10px;
}

.fp {
    align-items: flex-start;
}

.fp label {
    width: 200px;
    color: #ecf0f1;
    border: 1px solid var(--primary);
    cursor: pointer;
    padding: 7px 10px;
    border-radius: 8px;
    flex-direction: row;
}

.fp label i {
    margin-right: 5px;
    color: var(--primary);
}

.fp img {
    height: 200px !important;
    display: block;
    margin-left: 0 !important;
    margin-top: 10px;
    width: auto !important;
}

.err {
    color: #d63031;
    font-size: 12px;
    margin-top: 5px;
}

.Toastify__toast-container,
.Toastify__toast-container * {
    display: block;
    color: #000;
    flex-direction: row;
}

.loader {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.6);
    z-index: 7;
}

/* ============================ Comon css end =========================================== */

.category {
    width: 200px;
    height: 70px;
    align-items: center;
    justify-content: center;
    margin: 10px;
}

.categories .add {
    margin-left: auto;
    margin-top: 20px;
}

.add-category {
    width: 100%;
    height: 100%;
    justify-content: center;
}

.add-category .cat-btn {
    width: 160px;
    margin-top: 20px;
}

.tournament {
    padding: 15px;
    align-items: center;
    justify-content: center;
    margin: 10px;
}

.tournament img {
    width: 100%;
    height: 85%;
}

.tournament .info {
    margin-top: 10px;
    width: 100%;
    border-top: 1px solid #95a5a6;
    padding-top: 7px;
    font-size: 14px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.tournament .info span:nth-child(2) {
    font-size: 12px;
    color: #ecf0f1;
}

.tournaments .wrapper {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    flex-wrap: wrap;
}

.tournaments .wrapper .add {
    height: 35px;
    margin-top: 0;
}

.teamCard {
    width: 220px;
    height: 250px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
    padding-bottom: 20px;
    /* overflow: hidden; */
}

.teamCard img {
    width: 100%;
}

.teamdropdown {
    margin-left: 20px;
}

.teamCard .info {
    justify-content: center;
}

.playerCard img {
    width: 180px;
    height: 180px;
}

.playerCard {
    height: 270px;
}

.playerCard .info {
    align-items: start;
    flex-direction: column;
}

.playerCard .info span:nth-child(2) {
    font-size: 12px;
    color: #ecf0f1;
}

.playerCard .info span:nth-child(3) {
    font-size: 12px;
    color: #ecf0f1;
}

.matchse {
    width: auto;
    height: 170px;
    padding: 10px;
    margin: 10px;
}

.matchse img {
    width: 50px;
    height: 50px;
    border-radius: 10px;
}

.matchse .vs {
    font-size: 30px;
    margin: 0 20px;
    background: var(--primary);
    padding: 10px;
    border-radius: 100px;
}

.matchse section:first-child {
    flex-direction: row;
    border-bottom: 1px solid #95a5a6;
    padding-bottom: 5px;
}

.teamName {
    font-size: 12px;
    margin-top: 5px;
    width: 100px;
    text-align: center;
    line-height: 18px;
}

.team {
    align-items: center;
}

.matchse section span {
    font-size: 13px;
    margin-top: 8px;
}

.wrapper label {
    background-color: #1e1e1e;
}

.wrapper input {
    width: 500px;
}

.tournamentinput .wrapper {
    margin: 15px 3px;
}

.tournamentinput .wrapper .dropdown {
    margin-top: -8px;
}

.tournamentinput .dropdown {
    margin-top: 20px;
}
.create {
    width: 150px;
    margin-top: 30px;
    font-size: 15px;
}

.teaminput .wrapper input {
    width: 400px;
    background-color: transparent;
}

.teaminput .wrapper {
    margin-top: 20px;
}

.matche {
    margin: 15px 5px;
    width: 500px;
}

.matche label {
    background-color: #1e1e1e;
}

.machesinput {
    margin-top: 10px;
}

.machesinput img {
    width: 200px;
    height: 200px;
    margin-left: 12%;
}

.wrapper .nationality {
    margin-left: 0;
}

.teamimage {
    margin-left: 0;
    width: 200px;
}

.teamimage label {
    justify-content: left;
    padding-left: 7px;
}

#vs {
    width: 80px;
    height: 40px;
    margin: auto;
}

.groundcard {
    height: 90px;
    width: 250px;
}

.groundcard section {
    text-align: center;
    justify-content: center;
}

.groundcard section span {
    font-size: 18px;
}

.groundcountry span {
    font-size: 13px;
    margin-top: 20px;
    color: #95a5a6;
}

.addplayer {
    flex-direction: row;
}

.addplayer .card {
    width: 100%;
    height: calc(100vh - 190px);
    margin-top: 20px;
    cursor: auto;
    padding: 20px;
    overflow-y: auto;
    box-shadow: none;
}

.addplayer .card::after {
    content: "";
    display: none !important;
}

.addplayer section {
    width: 50%;
    padding-right: 20px;
}

.addplayer:nth-child(2) section:nth-child(2) {
    padding: 0 20px;
}

.addplayer h4 {
    margin-top: 15px;
    color: #95a5a6;
}

.addplayer h5 {
    margin-top: 15px;
    color: #95a5a6;
    text-align: end;
    font-size: 16px;
}

.playername {
    border-bottom: 1px solid #ecf0f1;
    width: 100%;
    padding: 10px 0;
    font-size: 14px;
    cursor: pointer;
    transition: all 300ms linear;
    flex-direction: row;
    align-items: center;
}

.playername:hover {
    color: var(--primary);
    border-bottom: 1px solid var(--primary);
}

.playername.active {
    color: var(--primary);
    border-bottom: 1px solid var(--primary);
}

.playername i {
    margin-right: 5px;
    color: var(--primary);
}

.playerteam {
    flex-direction: row;
    justify-content: space-between;
}

.player-img {
    width: 50px;
    height: 50px;
    margin-right: 15px;
}

.team-cont {
    flex-direction: row;
    flex-wrap: wrap;
}

.team-players-total {
    margin-left: 40px;
    width: 500px;
    max-width: 100%;
    background: #000;
    border-radius: 20px;
    padding: 20px;
    margin-top: 20px;
}

.team-players-total h2 {
    font-size: 16px;
    font-weight: normal;
    color: #95a5a6;
}

.selected-players {
    overflow-y: auto;
    height: calc(100vh - 200px);
}

.tournaments .player-name {
    font-size: 15px;
    margin: 7.5px 0;
}

.player-sec {
    flex-direction: row;
    align-items: center;
}

.player-sec .clear {
    margin-left: 15px;
    color: var(--primary);
    cursor: pointer;
    font-size: 20px;
}

.player-sec .clear:hover {
    font-weight: bold;
    color: red;
}

.scoring {
    width: 100%;
}

.scoring .score-container h3 {
    font-weight: normal;
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 5px;
    margin-left: 10px;
    /* border-bottom: 1px solid #95a5a6; */
}

.scoring .score-container section {
    flex-direction: row;
    flex-wrap: wrap;
}

.scoring .score-container section .run {
    width: 150px;
    height: 50px;
    align-items: center;
    justify-content: center;
    margin: 10px;
}

.scoring {
    margin-top: 20px;
}

.scoring h2 {
    font-size: 24px;
    font-weight: normal;
    color: #95a5a6;
    margin-bottom: 20px;
}

.scoring .dropdown-container {
    margin-bottom: 10px;
}

.scoring .dropdown-container .dropdown {
    margin: 10px 0;
}

.scoring .dropdown-container span {
    font-size: 14px;
    color: #ecf0f1;
}

.score-container .players-selection {
    flex-direction: row;
}

.score-container .players-selection .player {
    min-width: 150px;
    margin: 0 10px;
    border: 1px solid var(--primary);
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
}

.score-container .players-selection .player.selected {
    background: var(--primary);
}

.scoring .sec {
    border: 1px solid #95a5a6;
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 10px;
}

.scoring #toss .btn {
    width: 100px;
}

.scoring .live-results {
    flex-direction: row;
    overflow-y: auto;
}

.scoring .live-results .live-result {
    width: 75px;
    height: 75px;
    margin: 0 7.5px;
    border-radius: 100%;
    cursor: pointer;
    border: 1px solid var(--primary);
    margin-bottom: 40px;
    justify-content: center;
    align-items: center;
}

.scoring .live-result {
    background: transparent;
}

.scoring .live-result:disabled {
    background: #333;
}

.scoring .live-results .res {
    font-size: 10px;
}

.scoring .live-results .over {
    font-size: 14px;
    margin-top: 5px;
}

.scoring .live-result.active {
    background: var(--primary);
    color: #fff !important;
}

.scoring .live-result.active .res {
    color: #fff;
}

.scoring .evening-change {
    width: 250px;
    margin-left: auto;
}

.scoring .live-score {
    background: #000;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
}

.scoring .live-score .scores {
    flex-direction: row;
    justify-content: space-evenly;
}

.scoring .live-score .scores section {
    background: #1e1e1e;
    width: 250px;
    height: 130px;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 10px;
}

.scoring .live-score .scores div {
    color: #fff;
}

.scoring .live-score .scores .players .player {
    flex-direction: row;
    margin-bottom: 7.5px;
}

.scoring .live-score .scores .team-name {
    margin-bottom: 10px;
    font-weight: bold;
    padding: 10px;
    border-bottom: 1px solid #ecf0f1;
    width: 100%;
    text-align: center;
    color: #ecf0f1;
    letter-spacing: 2px;
}

.lineup {
    width: 100%;
    height: 100vh;
    overflow-y: hidden;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 20px;
    flex-wrap: wrap;
}

.lineup section {
    width: calc(50% - 20px);
    background: #000;
    height: calc(100% - 100px);
    overflow-y: hidden;
    border-radius: 10px;
    padding: 20px;
}

.lineup section .team-name {
    font-size: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #95a5a6;
}

.lineup section .tabs {
    flex-direction: row;
    margin-top: 20px;
}

.lineup section .tabs .tab {
    width: 150px;
    border: 1px solid var(--primary);
    margin: 0 10px;
    padding: 7px 10px;
    border-radius: 5px;
    cursor: pointer;
    color: var(--primary);
}

.lineup section .tabs .active-tab {
    width: 150px;
    background: var(--primary);
    margin: 0 10px;
    padding: 7px 10px;
    border-radius: 5px;
    cursor: pointer;
    color: #fff;
}

.lineup section .players {
    overflow-y: auto;
}

.lineup section .players .player {
    flex-direction: row;
    font-size: 16px;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #ecf0f1;
    cursor: pointer;
}

.lineup section .players .player-active {
    border-bottom: 1px solid #2ecc71;
    color: #2ecc71;
}

.lineup section .players .player-active.blue {
    border-bottom: 1px solid #3498db;
    color: #3498db;
}

.lineup section .players .player img {
    width: 50px;
    height: 50px;
}

.lineup section .players .player i {
    color: #2ecc71;
    margin-left: auto;
    margin-right: 20px;
}

.lineup section .players .player i.blue {
    color: #3498db;
}

.Dropdown-menu {
    max-height: 200px;
    overflow-y: auto;
}

.lineup button {
    background: var(--primary);
    color: #fff;
    font-size: 18px;
    padding: 5px 10px;
    border-radius: 5px;
    width: 100px;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    cursor: pointer;
    width: 180px;
    height: 50px;
    text-transform: uppercase;
}

.lineup button:disabled {
    opacity: 0.5;
}
